import React from 'react';
import logo from '../logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import '../App.css';


function IntroducaoPage() {
  const history = useHistory()
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        <section className="title">
          Introdução
        </section>

        <section className="content">
        Este app tem como finalidade auxiliar os médicos da atenção básica de saúde na identificação das lesões de pele suspeitas de malignidade
        </section>
        <section className="button-init">
          <Button variant="warning" size="lg" onClick={() => history.push("pagina-inicial")}>VAMOS LÁ</Button>
        </section>
      </main>
    </div>
  );
}

export default IntroducaoPage;
