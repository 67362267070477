import React from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'

function BiopsiaExcisionalMaculaPage() {
  const history = useHistory()
  const dados = JSON.parse(localStorage.getItem("dados"))
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        {
          dados.localizacaoLesao === "face" && dados.tamanhoLesao === "Menor que 0,5cm" ?
          <section className="content">
            <p><strong>Lesões na face pequenas (menores que 0,5cm) devem ser preferencialmente submetidas a biópsia excisional</strong></p>
          </section>  : null
        }

        {
          dados.localizacaoLesao === "corpo" && dados.tamanhoLesao === "Menor que 1,5cm" ?
          <section className="content">
            <p><strong>Lesões no corpo pequenas (menores que 1,5cm) devem ser preferencialmente submetidas a biópsia excisional</strong></p>
          </section>  : null
        }
        <section className="title">
            Conduta
        </section>
        <section className="content">
          <p>Encaminhar para biópsia excisional</p>
          
        </section>
        <section className="button-init">
          <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
            history.push("/resultado-biopsia")
          }}>Resultado</Button>
        </section>
      </main>
    </div>
  );
}

export default BiopsiaExcisionalMaculaPage;
