import React from 'react';
import logo from '../logo.png';
import mpcas from '../mpcas.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'

function HomePage() {
  localStorage.setItem("dados", JSON.stringify({}))
  const history = useHistory()
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src={mpcas} className="mpcas-logo" alt="logo" />
      </header>
      <main>
        <section className="authors">
          <p>Daniela Francescato Veiga</p>
          <p>Marcelo Prado de Carvalho</p>
          <p>José Wilson Moreira Filho</p>
        </section>
        <section className="button-init">
          <Button variant="warning" size="lg" onClick={() => history.push("/inicio")}>INICIAR</Button>
        </section>
      </main>
    </div>
  );
}

export default HomePage;
