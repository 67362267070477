import React, { Component } from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquare, faCheckSquare, faSortDown} from '@fortawesome/free-solid-svg-icons'
import brilhante_perolado_img from '../img/image7.png'
import crostosoImage from '../img/image17.png'
import pigmentadoImage from '../img/image18.png'
import ulceradoImage from '../img/image19.png'
import vegetanteImage from '../img/image20.png'

class CaracteristicasNoduloComponent extends Component{

    constructor(props){
        super(props)

        this.state = {
            viewDescription: false,
            viewCaracteristica: {},
            caracteristasNodulo : [
                {
                    id: "A",
                    titulo: "Brilhante/Perolado",
                    selected: false,
                    description: "",
                    image: brilhante_perolado_img
                },
                {
                    id: "B",
                    titulo: "Ulcerado (Por mais de 4 semanas)",
                    selected: false,
                    description: "",
                    image: ulceradoImage
                },
                {
                    id: "C",
                    titulo: "Pigmentado",
                    selected: false,
                    description: "",
                    image: pigmentadoImage
                },
                {
                    id: "D",
                    titulo: "Crostoso (sangramento esporádico)",
                    selected: false,
                    description: "",
                    image: crostosoImage
                },
                {
                    id: "E",
                    titulo: "Vegetante",
                    selected: false,
                    description: "",
                    image: vegetanteImage
                }
            ]
        }
    }

    selectCaracteristica = id => {
        const caracteristasNodulo = this.state.caracteristasNodulo.map(caracteristica => {
            if(caracteristica.id === id){
                caracteristica.selected = !caracteristica.selected
            }
            return caracteristica
        })

        this.setState({caracteristasNodulo})

        const caracteristicasSelecionadas = caracteristasNodulo.filter(caracteristica => caracteristica.selected === true)
        let dados = JSON.parse(localStorage.getItem("dados"))

        if(caracteristicasSelecionadas.length === 0){
            dados.msgAfterTitle = "A lesão não apresenta características de malignidade"
        }
        
        dados.caracteristicasSelecionadas = caracteristicasSelecionadas

        localStorage.setItem("dados", JSON.stringify(dados))
        this.props.history.push("melanoma")
    }

    viewCaracteristica = caracteristica => {
        this.setState({
            viewCaracteristica: caracteristica,
            viewDescription: true
        })
    }
    
    render(){
        return (
            <div className="App">
                <Modal show={this.state.viewDescription} onHide={() => this.setState({viewDescription: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.viewCaracteristica.titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.viewCaracteristica.description}
                        <img src={this.state.viewCaracteristica.image} className="imgCriterio rounded" alt="SkinScan"/>
                    </Modal.Body>
                </Modal>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main>
                    <section className="title">
                        A lesão se encaixa em alguma dessas características?
                    </section>

                    <section className="content">
                        {
                            this.state.caracteristasNodulo.map(caracteristica => {
                                return (
                                    <div key={caracteristica.id} className="caracteristica">
                                        <div onClick={() => this.selectCaracteristica(caracteristica.id)}>
                                            {
                                                caracteristica.selected ? <FontAwesomeIcon icon={faCheckSquare}/> : <FontAwesomeIcon icon={faSquare}/>
                                            }
                                            &nbsp;{caracteristica.titulo}
                                        </div>
                                        <Button bsPrefix="btn-info" variant="secondary" onClick={() => this.viewCaracteristica(caracteristica)}>
                                            ?
                                        </Button>
                                    </div>
                                )
                            })
                        }

                        <div className="caracteristica">
                            <div onClick={() => this.selectCaracteristica(0)}>
                            
                                <FontAwesomeIcon icon={faSquare}/>
                            
                                &nbsp;A lesão não se encaixa nestas características
                            </div>
                        </div>
                    </section>
                    {/* <section className="button-init">
                        <Button variant="warning" size="lg" onClick={() => {
                            const caracteristicasSelecionadas = this.state.caracteristasNodulo.filter(caracteristica => caracteristica.selected === true)
                            let dados = JSON.parse(localStorage.getItem("dados"))
                            
                            dados.caracteristicasSelecionadas = caracteristicasSelecionadas

                            localStorage.setItem("dados", JSON.stringify(dados))
                            this.props.history.push("melanoma")
                        }}>Avançar</Button>
                    </section> */}
                </main>
            </div>
        )
    }
}

function CaracteristicasNoduloPage() {
  const history = useHistory()
  return (
    <CaracteristicasNoduloComponent history={history}/>
  );
}

export default CaracteristicasNoduloPage;
