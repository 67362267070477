import React from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'

function DiagnosticoBiopsiaPage() {
  const history = useHistory()
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        <section className="title">
            Diagnóstico
        </section>
        <section className="content">
          <p>Tratar conforme diagnóstico da biópsia</p>
          
        </section>
        <section className="button-init">
          <Button variant="warning" size="lg" onClick={() => {
            history.push("/")
          }}>Finalizar</Button>
        </section>
      </main>
    </div>
  );
}

export default DiagnosticoBiopsiaPage;
