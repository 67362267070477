import React from 'react'
import logo from '../logo.png'
import {Button} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const CondutaPage = () => {
    const history = useHistory()
    return (
        <>
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main>
                    <section className="title">
                        Conduta
                    </section>
                    <section className="content">
                        A lesão deve ser reavaliada em 3 meses
                        <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => history.push("/")}>Finalizar</Button>
                    </section>
                </main>
            </div>
        </>
    )
}

export default CondutaPage