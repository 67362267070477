import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import logo from '../logo.png';

const LocalizacaoLesaoPage = () => {
    const history = useHistory()
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <main>
                
                <p>Qual a localizacão da lesão?</p>
                <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                    let dados = JSON.parse(localStorage.getItem("dados"))
                    dados.localizacaoLesao = "face"
                    // dados.msgEspecialista = "Lesões grandes que não se consiga realizar fechamento primário ou que possam gerar sequelas para o paciente devem ser encaminhadas para especialista."
                    localStorage.setItem("dados", JSON.stringify(dados))
                    history.push("/tamanho-lesao")
                }}>Face</Button>
                <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                    let dados = JSON.parse(localStorage.getItem("dados"))
                    dados.localizacaoLesao = "corpo"
                    // dados.msgEspecialista = "Lesões grandes que não se consiga realizar fechamento primário ou que possam gerar sequelas para o paciente devem ser encaminhadas para especialista."
                    localStorage.setItem("dados", JSON.stringify(dados))
                    history.push("/tamanho-lesao")
                }}>Corpo</Button>
                
            </main>
        </div>
    )
}

export default LocalizacaoLesaoPage