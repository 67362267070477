import React, { Component } from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquare, faCheckSquare} from '@fortawesome/free-solid-svg-icons'
import assimetriaImage from "../img/image13.png"
import bordasIrregularesImage from "../img/image14.png"
import coresMultiplasImage from "../img/image15.png"
import diametroImage from "../img/image16.png"
import evolucaoImage from "../img/image21.png"

class CriteriosDeAnaliseComponent extends Component{

    constructor(props){
        super(props)

        this.state = {
            viewDescription: false,
            viewCriterio: {},
            criteriosDeAnalise : [
                {
                    id: "A",
                    titulo: "Assimetria",
                    selected: false,
                    description: "Uma metade diferente da outra.",
                    image: assimetriaImage
                },
                {
                    id: "B",
                    titulo: "Bordas Irregulares",
                    selected: false,
                    description: "Contorno mal definido.",
                    image: bordasIrregularesImage
                },
                {
                    id: "C",
                    titulo: "Cores Múltiplas",
                    selected: false,
                    description: "Várias cores (preta, castanha, branca, avermelhada ou azul) numa mesma lesão.",
                    image: coresMultiplasImage
                },
                {
                    id: "D",
                    titulo: "Diâmetro",
                    selected: false,
                    description: "Maior que 6mm.",
                    image: diametroImage
                },
                {
                    id: "E",
                    titulo: "Evolução",
                    selected: false,
                    description: "Mudança do padrão recente da lesão.",
                    image: evolucaoImage
                }
            ]
        }
    }

    selectCriterio = id => {
        const criteriosDeAnalise = this.state.criteriosDeAnalise.map(criterio => {
            if(criterio.id === id){
                criterio.selected = !criterio.selected
            }
            return criterio
        })

        this.setState({criteriosDeAnalise})
    }

    viewCriterio = criterio => {
        this.setState({
            viewCriterio: criterio,
            viewDescription: true
        })
    }
    
    render(){
        return (
            <div className="App">
                <Modal show={this.state.viewDescription} onHide={() => this.setState({viewDescription: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.viewCriterio.titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.viewCriterio.description}
                        <img src={this.state.viewCriterio.image} className="imgCriterio" alt="SkinScan"/>
                    </Modal.Body>
                </Modal>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main>
                    <section className="title">
                    Características presentes na lesão
                    </section>

                    <section className="content">
                        {
                            this.state.criteriosDeAnalise.map(criterio => {
                                return (
                                    <div key={criterio.id} className="criterio">
                                        <div onClick={() => this.selectCriterio(criterio.id)}>
                                            {
                                                criterio.selected ? <FontAwesomeIcon icon={faCheckSquare}/> : <FontAwesomeIcon icon={faSquare}/>
                                            }
                                            &nbsp;{criterio.titulo}
                                        </div>
                                        <Button bsPrefix="btn-info" variant="secondary" onClick={() => this.viewCriterio(criterio)}>
                                            ?
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </section>
                    <section className="button-init">
                        <Button variant="warning" size="lg" onClick={() => {
                            const criteriosSelecionados = this.state.criteriosDeAnalise.filter(criterio => criterio.selected === true)
                            let dados = JSON.parse(localStorage.getItem("dados"))
                            
                            dados.criteriosSelecionados = criteriosSelecionados

                            localStorage.setItem("dados", JSON.stringify(dados))
                            this.props.history.push("melanoma")
                        }}>Avançar</Button>
                    </section>
                </main>
            </div>
        )
    }
}

function CriteriosDeAnalisePage() {
  const history = useHistory()
  return (
    <CriteriosDeAnaliseComponent history={history}/>
  );
}

export default CriteriosDeAnalisePage;
