import React, { useState } from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'
import macula_img from '../img/image4.png'
import nodulo_img from '../img/image2.png'
import placa_img from '../img/image12.png'

function InitialPage() {
  const history = useHistory()
  const [info, setInfo] = useState({})
  const [viewModal, setViewModal] = useState(false)
  return (
    <div className="App">
      <Modal show={viewModal} onHide={() => setViewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{info.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {info.description}
          <img src={info.img} className="imgCriterio rounded" alt="SkinScan"/>
        </Modal.Body>
      </Modal>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        <section className="title">
            Característica da lesão
        </section>
        <section className="buttons">
          <div>
            <Button bsPrefix="btn-select" variant="warning" size="lg" onClick={() => {
                let dados = JSON.parse(localStorage.getItem("dados"))
                if(dados === null){
                    dados = {}
                }
                dados.tipoLesao = "macula"
                localStorage.setItem("dados", JSON.stringify(dados))
                history.push("/criterios")
            }}>Mácula Pigmentada</Button>
            <Button bsPrefix="btn-info" variant="secondary" onClick={() => {
              setInfo({
                title: 'Mácula Pigmentada',
                description: 'Mancha de coloração escura sem relevo',
                img: macula_img
              })
              setViewModal(true)
            }}>
              ?
            </Button>
          </div>
          <div>
            <Button bsPrefix="btn-select" variant="warning" size="lg" onClick={() => {
                let dados = JSON.parse(localStorage.getItem("dados"))
                if(dados === null){
                    dados = {}
                }
                dados.tipoLesao = "nodulo"
                localStorage.setItem("dados", JSON.stringify(dados))
                history.push("/caracteristicas-nodulo")
            }}>Nódulo</Button>
            <Button bsPrefix="btn-info" variant="secondary" onClick={() => {
              setInfo({
                title: 'Nódulo',
                description: 'Lesão sólida elevada',
                img: nodulo_img
              })
              setViewModal(true)
            }}>
              ?
            </Button>
          </div>
          <div>
            <Button bsPrefix="btn-select" variant="warning" size="lg" onClick={() => {
                let dados = JSON.parse(localStorage.getItem("dados"))
                if(dados === null){
                    dados = {}
                }
                dados.tipoLesao = "placa"
                localStorage.setItem("dados", JSON.stringify(dados))
                history.push("/caracteristicas-placa")
            }}>Placa</Button>
            <Button bsPrefix="btn-info" variant="secondary" onClick={() => {
              setInfo({
                title: 'Placa',
                description: 'Lesão plana sem elevação',
                img: placa_img
              })
              setViewModal(true)
            }}>
              ?
            </Button>
          </div>
        </section>
      </main>
    </div>
  );
}

export default InitialPage;
