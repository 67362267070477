import React, { Component } from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquare, faCheckSquare, faSortDown} from '@fortawesome/free-solid-svg-icons'
import bordas_elevadas_img from '../img/image8.png'
import cicatricial_img from '../img/image9.png'
import eritematosa_img from '../img/image10.png'
import ulcerada_plana_img from '../img/image11.png'

class CaracteristicasPlacaComponent extends Component{

    constructor(props){
        super(props)

        this.state = {
            viewDescription: false,
            viewCaracteristica: {},
            caracteristasPlaca : [
                {
                    id: "A",
                    titulo: "Ulcerada com bordas elevadas",
                    selected: false,
                    description: "Ulcerada com bordas elevadas (crostosa)",
                    image: bordas_elevadas_img
                },
                {
                    id: "B",
                    titulo: "Ulcerada plana",
                    selected: false,
                    description: "Ulcerada plana (crostosa)",
                    image: ulcerada_plana_img
                },
                {
                    id: "C",
                    titulo: "Cicatricial (brilhante)",
                    selected: false,
                    description: "Cicatricial (brilhante)",
                    image: cicatricial_img
                },
                {
                    id: "D",
                    titulo: "Eritematosa (descamativa)",
                    selected: false,
                    description: "Eritematosa (descamativa)",
                    image: eritematosa_img
                }
            ]
        }
    }

    selectCaracteristica = id => {
        const caracteristasPlaca = this.state.caracteristasPlaca.map(caracteristica => {
            if(caracteristica.id === id){
                caracteristica.selected = !caracteristica.selected
            }
            return caracteristica
        })

        this.setState({caracteristasPlaca})

        const caracteristicasSelecionadas = caracteristasPlaca.filter(caracteristica => caracteristica.selected === true)
        let dados = JSON.parse(localStorage.getItem("dados"))

        if(caracteristicasSelecionadas.length === 0){
            dados.msgAfterTitle = "A lesão não apresenta características de malignidade"
        }
        
        dados.caracteristicasSelecionadas = caracteristicasSelecionadas

        localStorage.setItem("dados", JSON.stringify(dados))
        this.props.history.push("melanoma")
    }

    viewCaracteristica = caracteristica => {
        this.setState({
            viewCaracteristica: caracteristica,
            viewDescription: true
        })
    }
    
    render(){
        return (
            <div className="App">
                <Modal show={this.state.viewDescription} onHide={() => this.setState({viewDescription: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.viewCaracteristica.titulo}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.viewCaracteristica.description}
                        <img src={this.state.viewCaracteristica.image} className="imgCriterio rounded" alt="SkinScan"/>
                    </Modal.Body>
                </Modal>
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main>
                    <section className="title">
                        A lesão se encaixa em alguma dessas características?
                    </section>

                    <section className="content">
                        {
                            this.state.caracteristasPlaca.map(caracteristica => {
                                return (
                                    <div key={caracteristica.id} className="caracteristica">
                                        <div onClick={() => this.selectCaracteristica(caracteristica.id)}>
                                            {
                                                caracteristica.selected ? <FontAwesomeIcon icon={faCheckSquare}/> : <FontAwesomeIcon icon={faSquare}/>
                                            }
                                            &nbsp;{caracteristica.titulo}
                                        </div>
                                        <Button bsPrefix="btn-info" variant="secondary" onClick={() => this.viewCaracteristica(caracteristica)}>
                                            ?
                                        </Button>
                                    </div>
                                )
                            })
                        }
                        <div className="caracteristica">
                            <div onClick={() => this.selectCaracteristica(0)}>
                                <FontAwesomeIcon icon={faSquare}/>  
                                &nbsp;A lesão não se encaixa nestas características
                            </div>
                        </div>
                    </section>
                    <section className="button-init">
                        <Button variant="warning" size="lg" onClick={() => {
                            const caracteristicasSelecionadas = this.state.caracteristasPlaca.filter(caracteristica => caracteristica.selected === true)
                            let dados = JSON.parse(localStorage.getItem("dados"))
                            
                            dados.caracteristicasSelecionadas = caracteristicasSelecionadas

                            localStorage.setItem("dados", JSON.stringify(dados))
                            this.props.history.push("melanoma")
                        }}>Avançar</Button>
                    </section>
                </main>
            </div>
        )
    }
}

function CaracteristicasPlacaPage() {
  const history = useHistory()
  return (
    <CaracteristicasPlacaComponent history={history}/>
  );
}

export default CaracteristicasPlacaPage;
