import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import logo from '../logo.png';
import zonaHFace from '../img/zonaHFace.jpg'

const ZonaHFacePage = () => {
    const history = useHistory()
    let dados = JSON.parse(localStorage.getItem("dados"))
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <main>
                
                <p>Zona H da Face?</p>
                <img src={zonaHFace} className="img-fluid" />
                <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                    dados.msgZonaHFace = "Lesões que acometem areas nobres da face como palpebras, orelhas, labios e nariz devem ser encaminhadas para um especialista independente do tamanho da lesão."
                    dados.lesaoZonaHFace = "S"
                    localStorage.setItem("dados", JSON.stringify(dados))
                    history.push("/especialista")
                }}>Sim</Button>
                <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                    
                    dados.lesaoZonaHFace = "N"
                    // dados.msgEspecialista = "Lesões grandes que não se consiga realizar fechamento primário ou que possam gerar sequelas para o paciente devem ser encaminhadas para especialista."
                    localStorage.setItem("dados", JSON.stringify(dados))
                    history.push("/localizacao-lesao")
                }}>Não</Button>
                
            </main>
        </div>
    )
}

export default ZonaHFacePage