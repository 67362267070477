import React from 'react'
import { Button } from 'react-bootstrap';
import logo from '../logo.png';
import { useHistory } from 'react-router-dom';

const TamanhoLesaoPage = () => {
    const dados = JSON.parse(localStorage.getItem("dados"))
    const history = useHistory()
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <main>
                <p>Qual o tamanho da lesão</p>
                {
                    dados.localizacaoLesao === "face" ? 
                        <>
                            <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                                dados.tamanhoLesao = "Menor que 0,5cm"
                                localStorage.setItem("dados", JSON.stringify(dados))
                                if(dados.tipoLesao === "macula"){
                                    history.push("/biopsia-excisional")
                                }else{
                                    history.push("/biopsia")
                                }
                            }}>Menor que 0,5 cm</Button>
                            <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                                dados.msgEspecialista = "Lesões na face maiores que 0,5cm quando retiradas por profissional não especializado e sem Reconstrução adequada apresentam grandes chances de deixar sequelas nos pacientes."
                                dados.tamanhoLesao = "Maior que 0,5cm"
                                localStorage.setItem("dados", JSON.stringify(dados))
                                history.push("/especialista")
                            }}>Maior que 0,5 cm</Button>
                        </>
                    :
                        <>
                            <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                                dados.tamanhoLesao = "Menor que 1,5cm"
                                localStorage.setItem("dados", JSON.stringify(dados))
                                if(dados.tipoLesao === "macula"){
                                    history.push("/biopsia-excisional")
                                }else{
                                    history.push("/biopsia")
                                }
                            }}>Menor que 1,5 cm</Button>
                            <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                                dados.msgEspecialista = "Lesões no corpo maiores que 1,5cm geralmente requerem reconstruções mais elaboradas que devem ser realizadas por profissional especializado"
                                dados.tamanhoLesao = "Maior que 1,5cm"
                                localStorage.setItem("dados", JSON.stringify(dados))
                                history.push("/especialista")
                            }}>Maior que 1,5 cm</Button>
                        </>
                }
                
            </main>
        </div>
    )
}

export default TamanhoLesaoPage