import React from 'react';
import HomePage from './Pages/HomePage'
import IntroducaoPage from './Pages/IntroducaoPage';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import InitialPage from './Pages/InitialPage';
import CriteriosDeAnalisePage from './Pages/CriteriosDeAnalisePage'
import SuspeitaMelanomaPage from './Pages/SuspeitaMelanomaPage';
import EspecialistaPage from './Pages/EspecialistaPage';
import BiopsiaExcisionalPage from './Pages/BiopsiaExcisionalPage';
import DiagnosticoBiopsiaPage from './Pages/DiagnosticoBiopsiaPage';
import CaracteristicasNoduloPage from './Pages/CaracteristicasNoduloPage';
import wave from './wave.png'
import CaracteristicasPlacaPage from './Pages/CaracteristicasPlacaPage';
import LocalizacaoLesaoPage from './Pages/LocalizacaoLesaoPage';
import TamanhoLesaoPage from './Pages/TamanhoLesaoPage';
import ZonaHFacePage from './Pages/ZonaHFacePage';
import CondutaPage from './Pages/CondutaPage';
import ResultadoBiopsiaExcisionalPage from './Pages/ResultadoBiopsiaExcisionalPage';
import BiopsiaExcisionalMaculaPage from './Pages/BiopsiaExcisionalMaculaPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage/>
        </Route>
        <Route path="/inicio" exact>
          <IntroducaoPage/>
        </Route>
        <Route path="/pagina-inicial" exact>
          <InitialPage/>
        </Route>
        <Route path="/criterios" exact>
          <CriteriosDeAnalisePage/>
        </Route>
        <Route path="/caracteristicas-nodulo" exact>
          <CaracteristicasNoduloPage/>
        </Route>
        <Route path="/caracteristicas-placa" exact>
          <CaracteristicasPlacaPage/>
        </Route>
        <Route path="/melanoma" exact>
          <SuspeitaMelanomaPage/>
        </Route>
        <Route path="/especialista" exact>
          <EspecialistaPage/>
        </Route>
        <Route path="/biopsia-excisional" exact>
          <BiopsiaExcisionalMaculaPage/>
        </Route>
        <Route path="/biopsia" exact>
          <BiopsiaExcisionalPage/>
        </Route>
        <Route path="/diagnostico-biopsia" exact>
          <DiagnosticoBiopsiaPage/>
        </Route>
        <Route path="/localizacao-lesao" exact>
          <LocalizacaoLesaoPage />
        </Route>
        <Route path="/tamanho-lesao" exact>
          <TamanhoLesaoPage />
        </Route>
        <Route path="/zonaHFace" exact>
          <ZonaHFacePage />
        </Route>
        <Route path="/conduta" exact>
          <CondutaPage />
        </Route>
        <Route path="/resultado-biopsia" exact>
          <ResultadoBiopsiaExcisionalPage />
        </Route>
      </Switch>
      <img src={wave} className="bg-wave-bt" alt="wave"/>
      <img src={wave} className="bg-wave-tp" alt="wave"/>
    </Router>
  )
}

export default App;
