import React from 'react'
import logo from '../logo.png'
import {Button} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const ResultadoBiopsiaExcisionalPage = () => {
    const history = useHistory()
    return (
        <>
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
            </header>
            <main>
                <section className="title">
                    Resultado Biópsia
                </section>
                <section className="button-init">
                    <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                        history.push("/especialista")
                    }}>Positivo</Button>
                    <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => {
                        history.push("/diagnostico-biopsia")
                    }}>Negativo</Button>
                </section>
            </main>
        </div>
        </>
    )
}

export default ResultadoBiopsiaExcisionalPage