import React from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import {useHistory} from 'react-router-dom'



function EspecialistaPage() {
  const dados = JSON.parse(localStorage.getItem("dados"))
  const history = useHistory()
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main>
        {
          dados.lesaoZonaHFace === "S" ? 
          <section className="content"><p><strong>{dados.msgZonaHFace}</strong></p></section> : null  
        }
          {
            (dados.localizacaoLesao == "face" && dados.tamanhoLesao == "Maior que 0,5cm") || (dados.localizacaoLesao == "corpo" && dados.tamanhoLesao == "Maior que 1,5cm") ?
            <section className="content"><p><strong>{dados.msgEspecialista}</strong></p></section> : null  
          }
        <section className="title">
            Conduta
        </section>
        <section className="content">
          {
            (dados.localizacaoLesao == "face" && dados.tamanhoLesao == "Maior que 0,5cm") || (dados.localizacaoLesao == "corpo" && dados.tamanhoLesao == "Maior que 1,5cm") ?
            null : <p style={{margin: "40px 0"}}><strong>{dados.msgEspecialista}</strong></p>
          }
          <p>Encaminhar para especialista</p>
        </section>
        <section className="button-init">
          <Button variant="warning" size="lg" onClick={() => {
            history.push("/")
          }}>Finalizar</Button>
        </section>
      </main>
    </div>
  );
}

export default EspecialistaPage;
