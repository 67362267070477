import React, { Component } from 'react';
import logo from '../logo.png';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

class SuspeitaMelanomaComponent extends Component{

    constructor(props){
        super(props)

        const dados = JSON.parse(localStorage.getItem("dados"))

        let btnText = ""
        let btnLink = ""

        if(dados.tipoLesao === 'macula'){
            btnText = dados.criteriosSelecionados.length <= 1 ? 'Conduta' : 'Avançar'
            btnLink = dados.criteriosSelecionados.length <= 1 ? '/conduta' : '/conduta'
        }else{
            btnText = dados.caracteristicasSelecionadas.length === 0 ? 'Conduta' : 'Avançar'
            btnLink = dados.caracteristicasSelecionadas.length === 1 ? '/conduta' : '/conduta'
        }

        this.state = {
            dados,
            btnText,
            btnLink,
            msgBeforeTitle: dados.msgBeforeTitle,
            msgAfterTitle: dados.msgAfterTitle
        }
    }

    getContent = () => {
        if(this.state.dados.caracteristicasSelecionadas.length === 0){
            return (
                <>
                    {
                        this.state.msgAfterTitle ? 
                        <section className="content">
                            <strong> {this.state.msgAfterTitle}</strong>
                        </section> : null
                    }
                    <section className="button-init">
                        <Button variant="warning" size="lg" onClick={() => {
                            this.props.history.push(this.state.btnLink)
                        }}>{this.state.btnText}</Button>
                    </section>
                </>
            )
        }else{
            return (
                <section className="content">
                    <p>A lesão avaliada apresenta alta suspeita de malignidade.</p>
                    <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => this.props.history.push("/zonaHFace")}>Próximo</Button>
                </section>
            )
        }
    }

    getContentMacula = () => {
        if(this.state.dados.criteriosSelecionados.length <= 1){
            return (
                <>
                    <section className="content">
                        <p>A lesão avaliada apresenta baixa suspeita de melanoma.</p>
                    </section>
                    <section className="button-init">
                        <Button variant="warning" size="lg" onClick={() => {
                            this.props.history.push(this.state.btnLink)
                        }}>{this.state.btnText}</Button>
                    </section>
                </>
            )
        }else{
            return (
                <section className="content">
                    <p>A lesão avaliada apresenta alta suspeita de melanoma.</p>
                    <Button bsPrefix="btn-suspeita-melanoma" variant="warning" size="lg" onClick={() => this.props.history.push("/localizacao-lesao")}>Próximo</Button>
                </section>
            )
        }
    }
    
    render(){
        return (
            <div className="App">
                <header className="App-header">
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
                <main>
                    {
                        this.state.msgBeforeTitle ? 
                        <section className="content">
                           <strong> {this.state.msgBeforeTitle}</strong>
                        </section> : null
                    }
                    <section className="title">
                        Conclusão
                    </section>

                    {this.state.dados.tipoLesao === 'macula' ? this.getContentMacula() : this.getContent()}
                    
                </main>
            </div>
        )
    }
}

function SuspeitaMelanomaPage() {
  const history = useHistory()
  return (
    <SuspeitaMelanomaComponent history={history}/>
  );
}

export default SuspeitaMelanomaPage;
